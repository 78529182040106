import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useSoundTimeGlobalMeasureValues } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';
import { formatLocaleWithTimezone, getLocale } from '../utils/date';

import {
  SingleMeasureTable,
  OctaveMeasureTable,
  StatisticsMeasureTable,
  FFTMeasureTable,
} from './tables/globals';

const SoundGlobalMeasureTablePage = () => {
  const { workspaceId, soundSessionId, soundMeasureId } = useParams();
  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const {
    workspace,
    soundSession,
    timeSeries,
    soundMeasure,
    loading,
    error,
  } = useSoundTimeGlobalMeasureValues(workspaceId, soundSessionId, soundMeasureId);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const type = React.useMemo(() => {
    if (soundMeasure) {
      if (soundMeasure.kind === 'SoundTimeSingleMeasure') {
        return 'single';
      }
      if (soundMeasure.kind === 'SoundTimeOctaveMeasure') {
        return 'thirdOctave';
      }
      if (soundMeasure.kind === 'SoundTimeThirdOctaveMeasure') {
        return 'thirdOctave';
      }
      if (soundMeasure.kind === 'SoundTimeStatisticsMeasure') {
        return 'statistics';
      }
      if (soundMeasure.kind === 'SoundTimeFFTMeasure') {
        return 'fft';
      }
    }
    return null;
  }, [soundMeasure]);

  const timestamp = React.useMemo(() => {
    if (soundMeasure) {
      return soundMeasure.values[0].timestamp;
    }
    return null;
  }, [soundMeasure]);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
      if (soundSession) {
        result.push({
          path: `/workspaces/${workspaceId}/soundSessions/${soundSessionId}`,
          breadcrumbName: soundSession.name,
        });
        if (soundMeasure) {
          result.push({
            path: '',
            breadcrumbName: soundMeasure.name,
          });
        }
      }
    }
    return result;
  }, [workspace, soundSession, soundMeasure]);

  const subtitle = React.useMemo(() => {
    if (soundMeasure && soundSession) {
      const from = formatLocaleWithTimezone(soundSession.recordDate, 'yyyy/MM/dd HH:mm:ss:SSS', locale, soundSession.timezone, false);
      const to = formatLocaleWithTimezone(timestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, soundSession.timezone, false);
      return t('soundTimeMeasures.chart.subtitle', { from, to, totalValues: soundMeasure.totalValues });
    }
    return '';
  }, [t, soundSession, soundMeasure, locale]);

  const timeSerieWithAttachments = React.useMemo(() => {
    const result = timeSeries.reduce((acc, timeSerie) => {
      const valuesWithAttachments = timeSerie.values.filter((v) => (
        v.attachments && v.attachments.length
      ));
      return [...acc, ...valuesWithAttachments];
    }, []);

    if (result.length) {
      return {
        id: 'attachmentId',
        values: result,
      };
    }

    return null;
  }, [timeSeries]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={soundMeasure ? soundMeasure.name : ''}
        breadcrumb={breadcrumb}
        subtitle={subtitle}
      >
        {type && timestamp && soundMeasure && (
          <>
            {soundMeasure && type === 'single' && (
              <SingleMeasureTable
                height={750}
                workspaceId={workspaceId}
                soundSession={soundSession}
                measure={soundMeasure}
              />
            )}
            {soundMeasure && type === 'thirdOctave' && (
              <OctaveMeasureTable
                height={750}
                workspaceId={workspaceId}
                soundSession={soundSession}
                measure={soundMeasure}
                currentConfig={soundMeasure.config[0]}
              />
            )}
            {soundMeasure && type === 'statistics' && (
              <StatisticsMeasureTable
                height={750}
                workspaceId={workspaceId}
                soundSession={soundSession}
                measure={soundMeasure}
                currentConfig={soundMeasure.config[0]}
              />
            )}
            {soundMeasure && type === 'fft' && (
              <FFTMeasureTable
                height={750}
                workspaceId={workspaceId}
                soundSession={soundSession}
                measure={soundMeasure}
                currentConfig={soundMeasure.config[0]}
              />
            )}
          </>
        )}
      </PageSection>
    </ComponentLoader>
  );
};

export default SoundGlobalMeasureTablePage;
