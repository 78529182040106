/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Typography,
} from 'antd';
import {
  VirtualizedTable,
} from '@deltaohm/ant-components';

import {
  getLocale,
  formatLocaleWithTimezone,
} from '../../../utils/date';

import { pascalToDecibel } from '../../../utils/converter';
import { DB_DECIMAL } from '../../charts/utils';

const TIMESTAMP_WIDTH = 250;
const VALUE_WIDTH = 125;

const OctaveMeasureTableModal = (props) => {
  const {
    soundSession,
    measure,
    height,
    currentConfig,
  } = props;

  const { timezone } = soundSession;

  const { i18n, t } = useTranslation();
  const locale = getLocale(i18n.language);

  const thisConfig = React.useMemo(() => {
    const result = measure.config.find((c) => c.measureId === currentConfig.measureId);
    return result;
  }, [measure, currentConfig]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: thisConfig.label,
        dataIndex: 'band',
        render: (value) => (
          <div style={{ textAlign: 'right', width: '100%' }}>
            <Typography.Text style={{ fontFamily: 'monospace' }}>
              {value}
            </Typography.Text>
          </div>
        ),
        width: VALUE_WIDTH,
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
        render: (value) => {
          let text;
          if (value != null) {
            text = pascalToDecibel(value, DB_DECIMAL).toFixed(DB_DECIMAL);
          }
          return (
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Typography.Text style={{ fontFamily: 'monospace' }}>
                {text}
              </Typography.Text>
            </div>
          );
        },
        width: VALUE_WIDTH,
      },
      {
        title: 'timestamp',
        dataIndex: 'timestamp',
        render: (timestamp) => {
          const text = formatLocaleWithTimezone(timestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
          return (
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Typography.Text style={{ fontFamily: 'monospace' }}>
                {text}
              </Typography.Text>
            </div>
          );
        },
        width: TIMESTAMP_WIDTH,
      },
    ];
    return result;
  }, [t, locale, timezone, thisConfig]);

  const dataSource = React.useMemo(() => {
    const { values } = measure;
    const result = thisConfig.bands.map((band, index) => {
      const data = {
        band,
        value: values[0].data[thisConfig.measureId][index],
        timestamp: values[0].timestamp,
      };
      return data;
    });
    return result;
  }, [measure, thisConfig]);

  return (
    <VirtualizedTable
      height={height}
      bordered
      size="small"
      columns={columns}
      rowKey={(record) => record.parameter}
      dataSource={dataSource}
      pagination={false}
    />
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    kind: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  currentConfig: PropTypes.shape({
    measureId: PropTypes.string.isRequired,
  }).isRequired,
  height: PropTypes.number,
};

const defaultProps = {
  height: 1500,
};

OctaveMeasureTableModal.propTypes = propTypes;
OctaveMeasureTableModal.defaultProps = defaultProps;

export default OctaveMeasureTableModal;
